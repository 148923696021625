import { ChangeEventHandler, FC, useId } from 'react';

import cn from 'classnames';

import { ReactComponent as SearchIcon } from 'icons/Search.svg';

import styles from './InputSearch.module.css';

export type InputProps = {
  value: string | undefined;
  onChange: ChangeEventHandler;
  placeholder?: string;
  errorText?: string;
  disabled?: boolean;
};

export const InputSearch: FC<InputProps> = ({ onChange, value, placeholder, errorText, disabled }) => {
  const labelId = useId();
  const boottonId = useId();

  return (
    <div className={styles.root}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <input
        className={cn(styles.input, { [styles.errorInput]: errorText })}
        aria-labelledby={labelId}
        placeholder={placeholder}
        aria-errormessage={errorText ? boottonId : undefined}
        disabled={disabled}
        onChange={onChange}
        value={value}
        autoFocus={false}
      />
      {errorText && (
        <div id={boottonId} className={styles.errorText}>
          {errorText}
        </div>
      )}
    </div>
  );
};
