import * as Yup from 'yup';

import { PassCode } from './createPass.entity';

export const validationCreatePass = (passCode: PassCode) =>
  Yup.object().shape({
    ...(passCode.isFullNameRequired ? { fullName: Yup.string().required('Заполните поле ФИО') } : {}),
    ...(passCode.isCarIdentityRequired ? { carIdentity: Yup.string().required('Введите номер авто') } : {}),
    ...(passCode.isCarBrandRequired ? { carBrandId: Yup.string().required('Выберите марку авто') } : {}),
  });
