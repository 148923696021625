import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ErrorResponse } from 'store/types';

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error;
};

export const getResponseError = (error: unknown) => {
  if (isFetchBaseQueryError(error)) {
    const data = error.data as ErrorResponse;

    if ('detail' in data) {
      return data.detail;
    }
  }

  return 'Ошибка';
};
