import { FC } from 'react';

import { Stub } from 'components';

export const NotFoundPage: FC = () => {
  return (
    <Stub
      isError
      subTitle="Возможно, вы ошиблись при вводе адреса или страница могла быть перемещена"
      title="Страница не найдена"
    />
  );
};
