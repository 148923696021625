import { FC, useId, useMemo, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as ChevronIcon } from 'icons/Chevron.svg';

import { TopOptions } from './parts/TopOptions';
import styles from './Select.module.css';
import { SelectBottomSheet } from './SelectBottomSheet';

export type Option = { id: number; name: string };

export type SelectProps = {
  label: string;
  value: number | undefined;
  onChange: (option: Option) => void;
  placeholder?: string;
  errorText?: string;
  disabled?: boolean;
  titleList: string;
  options: Array<Option>;
  topOptions?: Array<Option>;
};

export const Select: FC<SelectProps> = ({
  onChange,
  titleList,
  value,
  label,
  placeholder,
  errorText,
  disabled,
  options,
  topOptions,
}) => {
  const labelId = useId();
  const boottonId = useId();
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const handleClickOnSelect = () => {
    setIsOpenSelect(true);
  };

  const handleCloseSelect = () => {
    setIsOpenSelect(false);
  };

  const handleSelectOption = (option: Option) => {
    setIsOpenSelect(false);
    onChange(option);
  };

  const isCurrentName = useMemo(() => options.find((option) => option.id === value)?.name, [options, value]);
  const isCurrentValue = useMemo(() => options.find((option) => option.id === value)?.id, [options, value]);

  return (
    <div className={styles.root}>
      <label className={styles.label} aria-label={labelId}>
        {label}
      </label>
      <input
        className={cn(styles.input, { [styles.errorSelect]: errorText })}
        aria-labelledby={labelId}
        placeholder={placeholder}
        aria-errormessage={errorText ? boottonId : undefined}
        disabled={disabled}
        value={isCurrentName}
        readOnly
        onClick={handleClickOnSelect}
      />
      <div className={styles.chevronIcon} onClick={handleClickOnSelect}>
        <ChevronIcon />
      </div>
      {Boolean(errorText) && (
        <div id={boottonId} className={styles.errorText}>
          {errorText}
        </div>
      )}
      {topOptions && <TopOptions options={topOptions} onClick={onChange} />}

      <SelectBottomSheet
        isOpen={isOpenSelect}
        onClose={handleCloseSelect}
        title={titleList}
        options={options}
        onSelect={handleSelectOption}
        selectedValue={isCurrentValue}
      />
    </div>
  );
};
