import { omdApi } from 'store/api';

import { PassCode, PassCodeCreateData, PassCodeQuery } from '../createPass.entity';

const request = omdApi.injectEndpoints({
  endpoints: (builder) => ({
    passCode: builder.query<PassCode, PassCodeQuery>({
      query: (query) => ({
        url: '/v1/passes/code',
        params: query,
      }),
    }),
    passCodeCreate: builder.mutation<void, PassCodeCreateData>({
      query: (data) => ({
        method: 'POST',
        url: '/v1/passes/link',
        body: data,
      }),
    }),
  }),
});

export const { usePassCodeQuery, usePassCodeCreateMutation } = request;
