import { FC } from 'react';

import { Option } from 'components/Select/Select';

import styles from './TopOptions.module.css';

type Props = {
  options: Array<Option>;
  onClick: (option: Option) => void;
};

export const TopOptions: FC<Props> = ({ options, onClick }) => {
  return (
    <div className={styles.root}>
      {options.map((item) => (
        <div key={item.id} className={styles.chip} onClick={() => onClick(item)}>
          {item.name}
        </div>
      ))}
    </div>
  );
};
