import { FC, useMemo } from 'react';

import { Button, Input, Select, Spinner, Stub } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';

import { getResponseError } from 'utils/getResponseError';

import { PassCode } from './createPass.entity';
import { validationCreatePass } from './createPass.helpers';
import styles from './CreatePass.module.css';

type Props = {
  data?: PassCode;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  onSubmit: (values) => void;
};

export const CreatePass: FC<Props> = ({ error, isLoading, data, isError, onSubmit }) => {
  const initialValue = {
    ...(data?.isFullNameRequired ? { fullName: '' } : {}),
    ...(data?.isCarBrandRequired ? { carBrandId: null } : {}),
    ...(data?.isCarIdentityRequired ? { carIdentity: '' } : {}),
  };

  const errorText = useMemo(() => {
    return getResponseError(error);
  }, [error]);

  if (isLoading) {
    return (
      <div className={styles.rootSpinner}>
        <Spinner />
      </div>
    );
  }

  if (!data || isError) {
    const subTitleText = errorText === 'Пропуск уже создан.' ? 'Если его создали не вы, обратитесь в поддержку' : '';

    return <Stub isError title={errorText} subTitle={subTitleText} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>Оформление пропуска</div>
        <div className={styles.subTitle}>{data.villageName}</div>
      </div>
      <div className={styles.form}>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={validationCreatePass(data)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {() => (
            <Form>
              <div className={styles.fieldsBlock}>
                <div className={styles.fieldBlock}>
                  <Input label="Кого ждем" value={data.name} disabled={true} onChange={() => {}} />
                </div>

                {data.isFullNameRequired && (
                  <div className={styles.fieldBlock}>
                    <Field name="fullName">
                      {({ field: { name, value }, form: { setFieldValue, errors } }: FieldProps) => (
                        <Input
                          label="ФИО"
                          placeholder="Введите фамилию, имя и отчество"
                          value={value}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(name, event.target.value);
                          }}
                          errorText={errors[name] ? String(errors[name]) : ''}
                        />
                      )}
                    </Field>
                  </div>
                )}
                {data.isCarBrandRequired && (
                  <div className={styles.fieldBlock}>
                    <Field name="carBrandId">
                      {({ field: { name, value }, form: { setFieldValue, errors } }: FieldProps) => (
                        <Select
                          label="Марка"
                          onChange={(value) => {
                            setFieldValue(name, value.id);
                          }}
                          value={value}
                          placeholder="Выберите из списка"
                          titleList="Выберите марку"
                          options={data.carBrands}
                          topOptions={data.topCarBrands}
                          errorText={errors[name] ? String(errors[name]) : ''}
                        />
                      )}
                    </Field>
                  </div>
                )}
                {data.isCarIdentityRequired && (
                  <div>
                    <Field name="carIdentity">
                      {({ field: { name, value }, form: { setFieldValue, errors } }: FieldProps) => (
                        <Input
                          label="Номер"
                          placeholder="Введите гос номер машины"
                          value={value}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(name, event.target.value);
                          }}
                          errorText={errors[name] ? String(errors[name]) : ''}
                        />
                      )}
                    </Field>
                  </div>
                )}
              </div>
              <div className={styles.footer}>
                <Button>Оформить</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
