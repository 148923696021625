import { FC, ReactNode } from 'react';

import styles from './Button.module.css';

type PropsButton = {
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

export const Button: FC<PropsButton> = ({ children, disabled, onClick }) => {
  return (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};
