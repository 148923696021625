import { ChangeEvent, FC, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import cn from 'classnames';

import { ReactComponent as CloseIcon } from 'icons/Close.svg';

import { InputSearch } from './parts/InputSearch';
import { Option } from './Select';
import styles from './Select.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (option: Option) => void;
  title: string;
  options: Array<Option>;
  selectedValue?: number;
};

export const SelectBottomSheet: FC<Props> = ({ isOpen, title, options, selectedValue, onClose, onSelect }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchList, setSearchList] = useState<Array<Option>>(options);

  const handleChangeInputSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    const filteredOption = options.filter(
      (option) => option.name.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) >= 0,
    );
    setSearchList(filteredOption);
  };

  const handleOptionClick = (option: Option) => {
    onSelect(option);
  };

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 5, maxHeight * 0.6]}
      className={styles.rootBottomSheet}
      header={
        <>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.close} onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.search}>
            <InputSearch onChange={handleChangeInputSearch} value={searchValue} placeholder="Поиск" />
          </div>
        </>
      }
    >
      <div className={styles.selectModalRoot}>
        <div className={styles.contentList}>
          {searchList.map((car) => (
            <div
              key={car.id}
              className={cn(styles.elementList, { [styles.elementListSelected]: selectedValue === car.id })}
              onClick={() => handleOptionClick(car)}
            >
              {car.name}
            </div>
          ))}
          {options.length === 0 && <div>Список пуст</div>}
        </div>
      </div>
    </BottomSheet>
  );
};
