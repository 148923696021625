import { createSlice } from '@reduxjs/toolkit';

export interface GlobalState {
  drawerIsOpen: boolean;
}

const initialState: GlobalState = {
  drawerIsOpen: true,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeStateDrawer: (state) => {
      state.drawerIsOpen = !state.drawerIsOpen;
    },
  },
});

export const { changeStateDrawer } = globalSlice.actions;

export default globalSlice.reducer;
