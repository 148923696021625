import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { CreatePassScreen, NotFoundScreen, PassStatusStubScreen } from 'Screens';

import { MainLayout } from './Layouts';
import { Providers } from './Providers';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <Providers>
          <MainLayout />
        </Providers>
      }
    >
      <Route path="pass-status" element={<PassStatusStubScreen />} />
      <Route path=":id" element={<CreatePassScreen />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Route>,
  ),
);
