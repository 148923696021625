import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import 'react-spring-bottom-sheet/dist/style.css';

import './index.css';
import { router } from './router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<RouterProvider router={router} />);
