import { memo } from 'react';
import { Outlet } from 'react-router-dom';

export const MainLayout = memo(() => {
  return (
    <div>
      <div style={{ width: '100%' }}>
        <div className="main-blok">
          <Outlet />
        </div>
      </div>
    </div>
  );
});
