import { memo, ReactNode } from 'react';
import { Provider } from 'react-redux';

import { SnackbarProvider } from 'notistack';

import { store } from './store/configureStore';

type Props = {
  children: ReactNode;
};

function Component({ children }: Props) {
  return (
    <SnackbarProvider>
      <Provider store={store}>{children}</Provider>
    </SnackbarProvider>
  );
}

export const Providers = memo<Props>(({ children }) => <Component>{children}</Component>);
