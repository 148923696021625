import { FC } from 'react';

import { ReactComponent as BadSmileIcon } from 'icons/BadSmile.svg';
import { ReactComponent as CheckIcon } from 'icons/Check.svg';

import styles from './PassStatusStubPage.module.css';

type Props = {
  isError: boolean;
  title: string;
  subTitle: string;
};

export const Stub: FC<Props> = ({ isError, subTitle, title }) => {
  return (
    <div className={styles.root}>
      <div className={styles.iconBlock}>
        {!isError && <CheckIcon />}
        {isError && <BadSmileIcon />}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>

      <div className={styles.footer}>
        <a className={styles.buttonLink} href="tel:*1223">
          Звонок
        </a>
        <a className={styles.buttonLink} href="https://t.me/VillagioVip_bot" target="_blank" rel="noreferrer">
          Telegram
        </a>
        <a className={styles.buttonLink} href="https://wa.me/74959740000" target="_blank" rel="noreferrer">
          Whatsapp
        </a>
      </div>
    </div>
  );
};
