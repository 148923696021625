import { ChangeEventHandler, FC, useId } from 'react';

import cn from 'classnames';

import styles from './Input.module.css';

export type InputProps = {
  label: string;
  value: string | undefined;
  onChange: ChangeEventHandler;
  placeholder?: string;
  errorText?: string;
  disabled?: boolean;
};

export const Input: FC<InputProps> = ({ onChange, value, label, placeholder, errorText, disabled }) => {
  const labelId = useId();
  const boottonId = useId();

  return (
    <div className={styles.root}>
      <label className={styles.label} aria-label={labelId}>
        {label}
      </label>
      <input
        className={cn(styles.input, { [styles.errorInput]: errorText })}
        aria-labelledby={labelId}
        placeholder={placeholder}
        aria-errormessage={errorText ? boottonId : undefined}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      {errorText && (
        <div id={boottonId} className={styles.errorText}>
          {errorText}
        </div>
      )}
    </div>
  );
};
