import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'constants/env';

//import { Login } from './auth/types';

export const omdApi = createApi({
  reducerPath: 'omd',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ['methodOfReceptionList'],
  endpoints: () => ({}),
});
